import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import "../templates/treatmentOptions.scss"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"
import CTA from "../components/CTA/CTA"
import SEO from "../components/seo"
import { Link } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"

const BackToBusinessPage = props => {
  const practice_name =
    "Amarillo Oral & Maxillofacial Surgery & Dental Implants"
  // If no video consult, set design to two. Else, set to three.
  const design = "two"
  const biz_tel = "(806) 353-1055"
  const biz_tel_stripped = biz_tel.replace(/\D/g, "")
  // If no video consult, set let youtube to no-youtube. Else, set to any string.
  const youtube = "no-youtube"
  const youtube_code = ""
  const youtubeImageId = ""
  const form_url = ""

  return (
    <SharedStateProvider>
      <Layout>
        <SEO
          title="We Are Open For Care in Amarillo, TX"
          description="We are excited to announced we are open for care in Amarillo, TX. Please call us to schedule an appointment with our practice."
        />
        <div className="joshua-tree-content">
          <div className="b2b-banner">
            <h1>WE ARE OPEN</h1>
          </div>
          <div className="b2b-top-section">
            <div className="columns">
              <div className="column is-5"></div>
              <div className="column">
                <p className="top-p">
                  The entire {practice_name} team is excited to resume both
                  emergency and elective treatments again and fulfill your oral
                  health needs. We are scheduling appointments now.{" "}
                </p>
              </div>
              <div className="column is-5"></div>
            </div>

            <div className="treatment-grid">
              <div className="columns">
                <div className="column is-6"></div>
                <div className="treatment-column column">
                  <div className="icon-wrapper">
                    <img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/doors-icon-blue.svg" />
                  </div>
                  <h3>
                    Our Doors Are Open
                    <br />
                    For Our Community
                  </h3>
                  <p>
                    We are now scheduling appointments. If you need oral surgery
                    care, our team is here for&nbsp;you.
                  </p>
                </div>
                <div className="column is-1"></div>
                {design === "three" && (
                  <>
                    <div className="teleconsult-box treatment-column column">
                      <a
                        className="teleconsult-link"
                        target="_blank"
                        href={form_url}>
                        <div className="icon-wrapper">
                          <img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/teleconsult-icon-blue.svg" />
                        </div>
                        <h3>
                          Offering Online Video
                          <br />
                          Appointments
                        </h3>
                        <p>
                          If you cannot visit the office, we are offering online
                          video consultations for you to be evaluated by
                          a&nbsp;specialist.
                        </p>

                        <div style={{ textAlign: "center" }}>
                          <a
                            target="_blank"
                            href={form_url}
                            className="b2b-button standard-button standalone has-text-centered">
                            Schedule Today
                          </a>
                        </div>
                      </a>
                    </div>
                    <div className="column is-1"></div>
                  </>
                )}
                <div className="treatment-column column">
                  <div className="icon-wrapper">
                    <img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/safety-icon-blue.svg" />
                  </div>
                  <h3>
                    Committed to
                    <br />
                    Keeping You Safe
                  </h3>
                  <p>
                    We are taking additional measures to ensure the health and
                    safety of our patients and staff while our office
                    remains&nbsp;open.
                  </p>
                </div>
                <div className="column is-6"></div>
              </div>
            </div>
          </div>

          <section
            className={"body-section " + youtube}
            style={{ paddingBottom: 0 }}>
            <div className="columns">
              <div className="column is-18 is-offset-3">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${youtube_code}`}
                  controls={true}
                  playing={true}
                  overVideo={true}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={youtubeImageId}
                    width="auto"></ImageMeta>
                </TheaterVideo>
              </div>
            </div>
          </section>

          <section className="body-section">
            <div className="b2b-body wrapper columns">
              <div className="column is-5"></div>

              <div className="column">
                <h5>Our Commitment to Health & Safety</h5>
                <p className="large">
                  As always, our practice takes every precaution to keep
                  patients and staff safe and healthy. While we have always
                  adhered to and exceeded the standards for decontamination set
                  by{" "}
                  <a target="_blank" href="https://www.osha.gov/coronavirus">
                    OSHA
                  </a>
                  , we are going one step further and incorporating the latest{" "}
                  <a
                    target="_blank"
                    href="https://www.cdc.gov/coronavirus/2019-ncov/index.html">
                    CDC
                  </a>{" "}
                  guidelines.
                </p>
                <h5>The Measures We Are Taking To Protect Our Patients</h5>
                <p className="large">
                  We ask you to keep the following in mind prior to and during
                  your appointment:
                </p>

                <ul>
                  <li>We will schedule to maintain social distancing</li>
                  <li>
                    We will continue to wear personal protective gear (gowns,
                    gloves, N95 masks, and face shields) as outlined by the CDC.
                  </li>
                  <li>
                    We will continue to use hospital-grade disinfectants to
                    clean each treatment room before and after each patient.
                  </li>
                  <li>
                    We will continue to practice the latest in sterilization
                    protocols to maintain the health and safety of our patients.
                  </li>
                </ul>

                <h5>How We Are Protecting the Safety of Our Staff & Doctors</h5>
                <p className="large">
                  We are taking additional steps toward ensuring the safety of
                  our staff.
                </p>
                <ul>
                  <li>
                    If you or someone in your household has a cough or flu-like
                    symptoms, we kindly ask that you reschedule your appointment
                    to protect the health of our staff.
                  </li>
                </ul>

                <p className="large">
                  <strong>IMPORTANT MESSAGE TO PATIENTS:</strong> If you or
                  someone in your household have a fever, cough, or flu-like
                  symptoms, please stay at home and don’t come to the practice.
                  Your doctor may request an online video consultation if you
                  are displaying symptoms.
                </p>

                <p className="large">
                  At Amarillo Oral & Maxillofacial Surgery & Dental Implants we
                  appreciate the ongoing support from our patients, referring
                  providers, families, and friends. Thank you for your patience
                  and cooperation while these measures are put into effect.
                </p>

                <p className="large">
                  For questions or clarification about these measures, please
                  contact us at{" "}
                  <a href={`tel:+1${biz_tel_stripped}`}>{biz_tel}</a>.
                </p>
              </div>
              <div className="column is-5"></div>
            </div>

            <div className="b2b-cta wrapper columns">
              <div className="column is-5"></div>
              <div className="column">
                <h5>Schedule Your Appointment Today</h5>
                <p>
                  Call our office to schedule an appointment for any procedure.{" "}
                </p>

                <a
                  href={`tel:+1${biz_tel_stripped}`}
                  className="standard-button b2b-button">
                  Call Us
                </a>
              </div>

              <div className="column is-5"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default BackToBusinessPage
